import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams, Link, Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Helmet} from "react-helmet";
import LinearProgress from "@material-ui/core/LinearProgress";

const getPhotoForSlug = gql`
query getPhotoForSlug($slug_name: ID!, $slug_sequence: Int!) {
  getSlug (slug_name: $slug_name, slug_sequence: $slug_sequence) {
      photo {
        attachment_uid
        slug
      }
  } 
}
`;

function Photo() {
    const { id, contentId } = useParams<{ id: string; contentId?: string; }>();
    const idSplit = id.split("--");

    const { loading, error, data } = useQuery(getPhotoForSlug, {
        variables: { slug_name: `Photo#${idSplit[0]}`, slug_sequence: idSplit[1] || 1 }
    });

    if (loading) return (
        <div style={{padding: "1rem"}}><LinearProgress color="secondary"/></div>
    );

    if (error) return <Typography>Error loading data</Typography>;

    const photo = data?.getSlug?.photo

    console.log('photo', photo)

    if (!photo) return (
        <div>
            <Redirect to="/404" />
        </div>
    )

    const imgUrl = `https://images.weknowwhatyoudidlastnight.com/${photo.attachment_uid}`
    const canonicalUrl = `https://www.weknowwhatyoudidlastnight.com/photos/${photo.slug}`
    const photoTitle = `Photo ${photo.slug}`

    return (
        <div style={{ padding: "1rem" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{photoTitle}</title>
                <link rel="canonical" href={canonicalUrl} />
                <meta property="og:title" content={photoTitle}></meta>
                <meta property="og:image" content={imgUrl}></meta>
                <meta property="og:url" content={canonicalUrl}></meta>
            </Helmet>
            {(contentId) && (<Link to={`/contents/${contentId}`} style={{ textDecoration: "none" }}>
                <span>
                    <Typography display="inline" variant="h6" color="textPrimary" style={{ marginBottom: "1rem" }}><ArrowBackIcon color="inherit" /> back to gallery</Typography>
                </span>
            </Link>)}
            <div>
                <img src={imgUrl} alt={photoTitle} />
            </div>
        </div>
    );
}

export default Photo;
